import React from 'react'
import { Link } from 'gatsby'
import { WrapperSection } from '../../wrappers'
import { s, colors } from '../../style'

const ButtonBig = ({ data, theme }) => {
  const { link_page, link_section, text, language } = data
  const url =
    (language ? `/${language}` : '') +
    (link_page ? `/${link_page}` : '') +
    (link_section ? `#${link_section}` : '')

  return (
    <WrapperSection noMarginTop>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}>
        <Link to={`/${language}/kontakt#${link_section}`} css={sLink(theme)}>
          {text}
        </Link>
      </div>
    </WrapperSection>
  )
}

const sLink = (theme) => ({
  transition: 'transform 0.3s ease-out',
  color: 'white',
  display: 'flex',
  textAlign: 'center',
  margin: '0 1.5rem',
  padding: '1rem 3rem',
  fontSize: '2rem',
  [s.xs]: {
    fontSize: '1.25rem',
  },
  textTransform: 'uppercase',
  background: `linear-gradient(to bottom right, ${colors[theme].mainLight}, ${colors[theme].mainDark})`,
  borderRadius: '4rem',
  fontWeight: 'bolder',
  '&:hover': {
    [s.hover]: {
      transform: 'scale(1.05)',
    },
  },
})

export default ButtonBig
